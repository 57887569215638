<template>
  <div>
    <b-modal
      ref="modalCancelVisita"
      :cancel-title="$t('Fechar')"
      cancel-variant="outline-secondary"
      size="lg"
      body-class=""
      :visible="false"
      :ok-title="$t('Guardar')"
      ok-variant="primary"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      @ok="confirmCancelVisita($event)"
      @cancel="hideModal"
    >
      <b-row>
        <b-col md="6">
          <b-form-group
            :label="$t('Data do evento')"
          >
            <date-picker
              v-model="dataSugestao"
              value-type="format"
              :input-class="{'form-control' : true}"
              type="date"
              :editable="false"
              :clearable="true"
              :lang="langCalendar"
              :append-to-body="true"
              prefix-class="sw"
            >
              <template #icon-clear>
                <v-select-deselect />
              </template>
            </date-picker>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group
            :label="$t('Hora do evento')"
          >
            <b-form-timepicker
              v-model="horaSugestao"
              placeholder="00:00"
              hide-header
              now-button
              :label-close-button="$t('Fechar')"
              :label-now-button="$t('Hora atual')"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12 mt-1">
          <h5>
            {{ $t('Justifique o motivo de rejeitar a visita.') }}
          </h5>
        </b-col>
        <b-col
          class="col-12"
        >
          <b-form-textarea
            v-model="valMotivo"
            placeholder=""
            rows="3"
          />
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  VBTooltip, BModal, BRow, BCol, BFormTextarea, BFormTimepicker, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import vSelect from 'vue-select'

const oDatepicker = () => Promise.all([
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ 'vue2-datepicker'),
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ '@/assets/scss/datepicker.scss'),
]).then(([DatePicker]) => DatePicker)

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormTextarea,
    DatePicker: oDatepicker,
    BFormTimepicker,
    BFormGroup,
    vSelectDeselect: vSelect.components.Deselect,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [sizeIconsAction],
  props: {
    showMsgErrorRequest: {
      type: Function,
      required: true,
    },
    showMsgSuccessRequest: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      modalData: [],
      processingAjax: false,
      activeRefreshListview: false,
      valMotivo: null,
      dataSugestao: null,
      horaSugestao: null,
      langCalendar: null,
    }
  },
  async mounted() {
    await this.importLocale(this.$i18n.locale).then(res => {
      this.langCalendar = res.default
    })
  },
  methods: {
    async importLocale(lang) {
      return import(`vue2-datepicker/locale/${lang}`)
    },
    showModal() {
      return new Promise(resolve => {
        this.$refs.modalCancelVisita.show()
        resolve(true)
      })
    },
    hideModal() {
      this.$refs.modalCancelVisita.hide()
    },
    async confirmCancelVisita(evt) {
      evt.preventDefault()
      const txtMotivo = this.valMotivo

      if (txtMotivo === null || txtMotivo === '') {
        this.showMsgErrorRequest(new Error(`${this.$t('Justifique o motivo de rejeitar a visita.')}`))
      } else {
        this.$root.$emit('app::loading', true)
        this.$store.dispatch('crm_visitas_listview/rejeitarVisita', { motivo: this.valMotivo, dataSugestao: this.dataSugestao, horaSugestao: this.horaSugestao.substring(0, 5) }).then(res => {
          if (res.error === 1) {
            this.showMsgErrorRequest(new Error(res.msg))
            this.$root.$emit('app::loading', false)
          } else {
            this.showMsgSuccessRequest({ message: res.msg })
            this.hideModal()
            this.$parent.showProcessoModalDetail = false
            this.$store.dispatch('crm_visitas_listview/fetchListView').catch(error => {
              this.showMsgErrorRequest(error)
            })
            this.$root.$emit('app::loading', false)
          }
        }).catch(error => {
          this.showMsgErrorRequest(error)
        })
      }
    },
    resetData() {
      this.valMotivo = null
    },
  },
}
</script>

<style lang="scss" scoped>

.force-padding-left-05
{
  padding-left: 0.5rem !important;
}

.force-padding-0
{
  padding:0 !important;
}

</style>
