<template>
  <div>
    <b-modal
      ref="modalCancelVisita"
      :cancel-title="$t('Não')"
      cancel-variant="outline-secondary"
      size="lg"
      body-class=""
      :visible="false"
      :ok-title="$t('Sim')"
      ok-variant="primary"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      @ok="confirmCancelVisita($event)"
      @cancel="hideModal"
    >
      <b-row class="">
        <b-col md="12">
          <h4>
            {{ $t('Tem a certeza que pretende cancelar esta visita?') }}
          </h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12 mt-1">
          <h5>
            {{ $t('Justifique o motivo de cancelar a visita.') }}
          </h5>
        </b-col>
        <b-col
          class="col-12"
        >
          <b-form-textarea
            v-model="valMotivo"
            placeholder=""
            rows="3"
          />
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  VBTooltip, BModal, BRow, BCol, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { sizeIconsAction } from '@core-custom/mixins/geral'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormTextarea,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [sizeIconsAction],
  props: {
    showMsgErrorRequest: {
      type: Function,
      required: true,
    },
    showMsgSuccessRequest: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      modalData: [],
      processingAjax: false,
      activeRefreshListview: false,
      valMotivo: null,
    }
  },
  mounted() {
  },
  methods: {
    showModal() {
      return new Promise(resolve => {
        this.$refs.modalCancelVisita.show()
        resolve(true)
      })
    },
    hideModal() {
      this.$refs.modalCancelVisita.hide()
    },
    async confirmCancelVisita(evt) {
      evt.preventDefault()
      const txtMotivo = this.valMotivo

      if (txtMotivo === null || txtMotivo === '') {
        this.showMsgErrorRequest(new Error(`${this.$t('Justifique o motivo de cancelar a visita.')}`))
      } else {
        this.$root.$emit('app::loading', true)
        this.$store.dispatch('crm_visitas_listview/cancelarVisita', txtMotivo).then(res => {
          if (res.error === 1) {
            this.showMsgErrorRequest(new Error(res.msg))
            this.$root.$emit('app::loading', false)
          } else {
            this.showMsgSuccessRequest({ message: res.msg })
            this.hideModal()
            this.$parent.showProcessoModalDetail = false
            this.$store.dispatch('crm_visitas_listview/fetchListView').catch(error => {
              this.showMsgErrorRequest(error)
            })
            this.$root.$emit('app::loading', false)
          }
        }).catch(error => {
          this.showMsgErrorRequest(error)
        })
      }
    },
    resetData() {
      this.valMotivo = null
    },
  },
}
</script>

<style lang="scss" scoped>

.force-padding-left-05
{
  padding-left: 0.5rem !important;
}

.force-padding-0
{
  padding:0 !important;
}

</style>
